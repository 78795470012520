<template>
    <el-container class="h-100">
      <meta name="viewport" content="width=device-width,user-scalable=no,initial-scale=1.0">
        <div class="h-100 w-500">
          <header class="h5_header">
            <h1>{{ h5Data.doc_header.doc_title }}</h1>
            <p>{{ h5Data.doc_header.doc_subtitle }}</p>
          </header>
          <div class="h5_nav mar-t-15">
            <a @click="handleClick('yqzl')" class="active">舆情总览</a>
            <a @click="handleClick('yqzd')">舆情重点</a>
            <a @click="handleClick('xybgz')">舆情总结与建议</a>
          </div>
          <div v-if="activeIndex == 'yqzl'" class="yqzl_cont">
              <div class="h5_card">
                  <p>{{ h5Data.section1.text1 }}</p>
                  <!-- 信息声量走势 -->
                  <div class="h5_card mar-t-15">
                      <h2 class="tit"><span>信息声量走势</span></h2>
                    <div class="map" id="source"></div>
                    <div class="echarts_empty hide">
                        <img src="/static/img/echarts_empty.png" alt="暂无数据" height="120" type="image/svg+xml">
                    </div>
                  </div>
                  <!-- 信息声量走势 -->
              </div>
              <div class="h5_card mar-t-15">
                  <p>{{ h5Data.section1.text2 }}</p>
                  <!-- 敏感信息占比 -->
                  <div class="h5_card mar-t-15">
                      <h2 class="tit"><span>敏感信息占比</span></h2>
                    <div class="map mar-t-15" id="sensitive"></div>
                    <div class="echarts_empty hide">
                        <img src="/static/img/echarts_empty.png" alt="暂无数据" height="120" type="image/svg+xml">
                    </div>
                  </div>
                  <!-- 敏感信息占比 -->
              </div>
              <div class="h5_card mar-t-15">
                  <p>{{ h5Data.section1.text3 }}</p>
                  <!-- 热门关键词 -->
                  <div class="h5_card mar-t-15">
                      <h2 class="tit"><span>热门关键词</span></h2>
                    <div class="map" id="words" v-show="h5Data.section1.diagram3.length >= 1"></div>
                    <div class="echarts_empty" v-if="h5Data.section1.diagram3.length < 1">
                        <img src="/static/img/echarts_empty.png" alt="暂无数据" height="120" type="image/svg+xml">
                    </div>
                  </div>
                  <!-- 热门关键词 -->
              </div>
          </div>
          <div v-if="activeIndex == 'yqzd'" class="yqzd_cont">
              <h2>{{ h5Data.section2.text1 }}</h2>
              <div class="h5_card mar-t-15" v-for="(item, index) in h5Data.section2.text2">
                <h3>{{ item.title }}</h3>
                <p>{{ item.desc }}</p>
                <p>{{ item.abstract.length >= 1 ? '文中称：' : '' }}{{ item.abstract }}</p>
              </div>
          </div>
          <div v-if="activeIndex == 'xybgz'" class="xybgz_cont">
              <div class="h5_card mar-t-15">
                  <p>{{ h5Data.section3.text1 }}</p>
                  <p>{{ h5Data.section3.text2 }}</p>
              </div>
          </div>
          <footer class="h5_footer" v-if="version === 'common'">数知舆情管理系统 版权所有 </footer>
        </div>
    </el-container>
</template>
<script>
  import { globalCreateChart, globalMediaColor } from "@/utils/helpers";
  import { detail } from "@/api/briefing";
  export default {
    name: "component-briefh5-mix",
    components: {
    },
    data() {
      return {
        activeIndex: "yqzl",
        h5Data: {},
        numberMap: ['一', '二', '三', '四', '五', '六'],
        version: process.env.VUE_APP_VERSION
      }
    },
    created() {
      this.loadData();
    },
    methods:{
      loadData() {
        const {id} = this.$route.query;
        if (!id) {
          this.$message.error('不存在该简报');
          return false;
        }
        detail({id: id}).then(res => {
          const {state, data} = res.data;
          if (state) {
            this.h5Data = JSON.parse(data.data);
            this.$nextTick(() => {
              this.source()
              this.sensitive()
              this.treemap()
            })
          } else {
            this.$message.error(res.data.error);
          }
        }).catch(err => {
          window.console.error(err)
        })
      },
      handleClick(index) {
        this.activeIndex = index
        let indexMap = ["yqzl", "yqzd", "xybgz"]
        if (index === 'yqzl') {
          this.$nextTick(() => {
            this.source()
            this.sensitive()
            this.treemap()
          })
        }
        _(indexMap).forEach((v, k) => {
          window.jQuery(".h5_nav a").removeClass("active")
          if (v == index) {
            window.jQuery(`.h5_nav a:eq(${k})`).addClass("active")
            return false
          }
        })
      },
      source() {
        var data = this.h5Data.section1.diagram1
        var kMaps = {}
        var total = 0
        var keys = []
        data.map(function (item) {
          total += item.count
        })
        data = data.map(function (item) {
          var rate = (item.count / total) * 100
          rate = rate.toFixed(2).toString()
          kMaps[item.key] = item.count + "条"
          keys.push(item.key)
          item.itemStyle = {color: globalMediaColor(item.key)}
          item.value = item.count
          item.name = item.key
          return item
        })
        var option = {
          animation: false,
          tooltip : {
            trigger: 'item',
            formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          legend: {
            orient: 'vertical',
            type: 'scroll',
            left: '0',
            bottom: "0",
            data: keys,
            itemWidth:10,
            itemHeight:10,
            itemGap:5,
            pageIconColor:"#555DFE",
            icon:"circle",
            formatter: function (name) {
              return name + " " + kMaps[name]
            }
          },
          series : [
            {
              name: '来源分布',
              type: 'pie',
              radius: ['45%', '60%'],
              center: ['50%', '40%'],
              data: data,
              itemStyle: {
                emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                },
                normal : {
                  label: {
                    show: false
                  },
                  labelLine: {
                    show: false
                  }
                }
              }
            }
          ]
        };
        var element = window.document.getElementById('source');
        if (!element) return false
        var chart = globalCreateChart(element, option)
        this.$nextTick(() => {
          window.addEventListener("resize", () => { chart.resize();})
        })
      },
      sensitive() {
        var result = this.h5Data.section1.diagram2
        let option = {
          tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b}: {c} ({d}%)"
          },
          legend: {
            // orient: 'vertical',
            // x: 'left',
            data:['敏感','非敏感'],
            top:"10px",
            itemWidth:10,
            itemHeight:10,
            itemGap:20,
            icon:"circle"
          },
          color: ['#FC5D73','#555DFE'],
          series: [
            {
              name:'情感分布',
              type:'pie',
              center:['50%', '60%'],
              radius: ['45%', '60%'],
              avoidLabelOverlap: false,
              label: {
                normal: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: '30',
                    fontWeight: 'bold'
                  }
                }
              },
              labelLine: {
                normal: {
                  show: false
                }
              },
              data: [{name: "敏感", value: result.neg_count}, {name: "非敏感", value: result.pos_count}]
            }
          ]
        }
        var element = window.document.getElementById('sensitive');
        if (!element) return false
        var chart = globalCreateChart(element, option)
        this.$nextTick(() => {
          window.addEventListener("resize", () => { chart.resize();})
        })
      },
      treemap() {
        let color = ['#FEBA86', '#26B3D8', '#76D4FF', '#888888', '#62AFFE'];
        let data = this.h5Data.section1.diagram3.map((item) => {
          let index = Math.floor((Math.random()*color.length))
          return {
            name: item.key,
            value: item.count,
            itemStyle: {
              color: color[index]
            }
          }
        })
        let option = {
          tooltip: {
            formatter: function (info) {
              return `${info.name}：${info.value}`;
            }
          },
          series: [{
            type: 'treemap',
            breadcrumb: {
              show: false
            },
            data: [{
              name: '热门关键词',            // First tree
              children: data
            }]
          }]
        }
        var element = window.document.getElementById('words');
        if (!element) return false
        var chart = globalCreateChart(element, option)
        this.$nextTick(() => {
          window.addEventListener("resize", () => { chart.resize();})
        })
      }
    }
}
</script>
<style scoped>
a:hover{text-decoration: none;}
.map{height: 300px;}
.h5_header{background-image: linear-gradient(90deg, #009ef6 0%, #5060d6 100%);width:100%;text-align: center;overflow: hidden;padding:10px 0;}
.h5_header h1{font-size:16px;line-height:40px;color:#fff;font-weight: 600;}
.h5_header p{line-height:20px;font-size:12px;color:#fff;}
.h5_nav{margin:15px 15px 0;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;}
.h5_nav a{height:30px;line-height:30px;background:#e5e5e5;font-size:12px;border-radius:15px;color:rgba(0,0,0,.5);display: inline-block;padding:0 15px;cursor: pointer;font-weight: 600;}
.h5_nav a.active{color:#fff;background-image: linear-gradient(90deg, #009ef6 0%, #5060d6 100%);}
.yqzl_cont,.yqzd_cont,.xybgz_cont{padding:15px;}
.h5_card{background-color: #ffffff;box-shadow:0px 2px 8px 0px rgba(194,194,194,0.2);border-radius:3px;padding: 15px;}
.h5_card h3,.h5_card p{font-size:12px;line-height:24px;color:rgba(0,0,0,.8);}
.h5_card h3,.tszl_cont dt{font-weight:600;}
.h5_card p{text-indent: 2em;color:rgba(51,51,51,.8);}
.h5_card .tit {border-left:2px solid #555DFE;padding-left:15px;font-weight:600;font-size:14px;line-height:14px;color:#000;}
.w-500{width: 100%;}
@media screen and (min-width:500px) {
  .w-500{width:500px;margin:0 auto;}
}
.hide{display: none;}
.h5_footer{text-align: center;font-size:12px;line-height:60px;color:rgba(0,0,0,.26);}
.yqzd_cont h2{font-size:12px;line-height:20px;color: #000;margin-bottom:15px;}
</style>